@import 'slick';
@import 'slick-theme';
* {
  font-family: 'Roboto', sans-serif;
  outline: none;
  color: #333;
  box-sizing: border-box;
  text-decoration: none;
}
::-webkit-input-placeholder {
  color: #333;
}
:-ms-input-placeholder {
  color: #333;
}
::-ms-input-placeholder {
  color: #333;
}
::placeholder {
  color: #333;
}
body {
  background: #FBFBFB;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}
img {
  display: block;
  max-width: 100%;
}
ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.wrap {
  max-width: 1170px;
  padding: 0 10px;
  margin: 0 auto;
}
.flex {
  display: flex;
  align-items: center;
}
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background-color: #fff;
  #cube-loader {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed;
    & .caption {
      margin: 0 auto;
    }
    .cube-loader {
      width: 73px;
      height: 73px;
      margin: 0 auto;
      margin-top: 49px;
      position: relative;
      transform: rotateZ(45deg);

      & .cube {
        position: relative;
        transform: rotateZ(45deg);
        width: 50%;
        height: 50%;
        float: left;
        transform: scale(1.1);
      }
      & .cube:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F56E17;
        animation: cube-loader 2.76s infinite linear both;
        transform-origin: 100% 100%;
      }
      & .loader-2 {
        transform: scale(1.1) rotateZ(90deg);
      }
      & .loader-3 {
        transform: scale(1.1) rotateZ(180deg);
      }
      & .loader-4 {
        transform: scale(1.1) rotateZ(270deg);
      }
      & .loader-2:before {
        animation-delay: 0.35s;
      }
      & .loader-3:before {
        animation-delay: 0.69s;
      }
      & .loader-4:before {
        animation-delay: 1.04s;
      }
    }
  }

  @keyframes cube-loader {
    0%, 10% {
      transform: perspective(136px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      transform: perspective(136px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      transform: perspective(136px) rotateY(180deg);
      opacity: 0;
    }
  }
}
.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

// header
.header {
  height: 63px;
  background: #fff;
  position: relative;
  z-index: 20;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  .flex {
    height: 100%;
    justify-content: space-between;
  }
  .header-left {
    display: flex;
    .header-logo {
      background: url('../img/header-logo.svg') no-repeat;
      width: 79px;
      display: block;
      height: 19px;
      transition: .3s;
      &:hover {
        transform: scale(1.2);
      }
    }
    .header-nav {
      display: flex;
      margin-left: 70px;
      .header-nav__link {
        margin: 0 20px;
        text-transform: uppercase;
        position: relative;
        transition: all .4s;
        &:before {
          content: '';
          height: 3px;
          width: 0%;
          background: transparent;
          background-position: center 0;
          position: absolute;
          top: -22px;
          transition: .2s;
        }
        &:hover, &--active {
          &:before {
            width: 100%;
            background: linear-gradient(270deg, #FF4141 0%, #FFA800 100%);
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .header-search {
      width: 27px;
      height: 27px;
      overflow: hidden;
      transition: .4s;
      &:hover {
        width: 224px;
        .header-search-form {
          width: 100%;
        }
      }
      .header-search-form {
        width: 27px;
        height: 27px;
        background: url('../img/header-search-icn.svg') no-repeat;
      }
      .header-search-input {
        border: none;
        background-color: transparent;
        margin-left: 27px;
        padding: 0 5px;
        width: 197px;
        height: 27px;
        border-bottom: 1px solid #000;
        box-sizing: border-box;
      }
    }
    .header-basket {
      width: 27px;
      height: 27px;
      margin-left: 40px;
      position: relative;
      background: url('../img/header-shopping-icn.svg') no-repeat 0 50%;
      .header-basket__item {
        position: absolute;
        right: -5px;
        top: -5px;
        color: #fff;
        font-size: 9px;
        text-transform: uppercase;
        background: linear-gradient(206.63deg, #1A4CCC 8.33%, #00A3FF 83.33%);
        border-radius: 50%;
        padding: 3px;
        display: flex;
        align-items: center;
        transition: .3s;
      }
      &:hover {
        .header-basket__item {
          transform: scale(1.2);
        }
      }
    }
  }
}

//news
.news {
  padding: 40px 0 45px;
  display: flex;
  background: radial-gradient(409.50px at 50% 50%, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 100%), #2D2D2D;
  .news-slider {
    & > div {
      .news-slider__item {
        padding: 0 20px;
      }
    }
    .news-slider__item {
      position: relative;
      overflow: hidden;
      .img {
        height: 490px;
        width: 100%;
        overflow: hidden;
        img {
          display: block;
          width: auto;
          width: 100%;
          height: 100%;
          transition: .4s;
          &:hover {

          transform: scale(1.1);
          }
        }
      }
    }
    .game-bundle {
      position: absolute;
      right: 10px;
      bottom: 32px;
      background: #fff;
      max-width: 80%;
      min-width: 65%;
      height: 200px;
      display: flex;
      .game-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 40px 25px 20px;
        .game-name {
          margin-bottom: 10px;
          line-height: 1;
        }
        .game-descr {
          margin: 0;
          line-height: 1.2;
        }
      }
    }
  }
}

// platforms
.platforms {
  height: 165px;
  padding: 16px 0;
  background: #3e3e3e;
  box-sizing: border-box;
  .platform-items {
    height: 133px;
    display: flex;
    justify-content: space-between;
    .platform-items__link {
      width: 100%;
      display: flex;
      .platform-items__img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto;
        transition: .3s;
        &.steam {
          background-color: #A6A9B1;
          background-image: url('../img/platform-steam.svg');
        }
        &.origin {
          background-color: #F56E17;
          background-image: url('../img/platform-origin.svg');
        }
        &.ubisoft {
          background-color: #0071FE;
          background-image: url('../img/platform-ubisoft.svg');
        }
        &.rockstar {
          background-color: #FFD379;
          background-image: url('../img/platform-rockstar.svg');
        }
        &.minecraft {
          background-color: #C5ECA6;
          background-image: url('../img/platform-minecraft.svg');
        }
        &:hover {
          background-position: 50% 40%;
        }
      }
    }
  }
}

// catalog
.catalog {
  padding: 100px 0;
  .catalog-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-shrink: 1;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    &.grid {
      display: flex;
      flex-shrink: 1;
      justify-content: space-between;
      .catalog-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 100%;
        width: calc(100% / 1.5 + 20px);
        flex-shrink: 1;
        .catalog-items__item {
          width: calc(50% - 30px);
          &:nth-child(3n) {
            margin-right: auto;
          }
        }
      }
      .catalog-filters {
        height: 100%;
        width: calc(100% / 3 - 20px);
        display: flex;
        flex-direction: column;
        margin-top: -68px;
        .filter-item {
          padding: 30px 22px 15px;
          margin-bottom: 10px;
          background: #fff;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
          .filter-item__title {
            text-align: center;
            font-size: 23px;
            line-height: 1;
            font-weight: 700;
            padding-bottom: 20px;
          }
        }
        .filter-btn {
            background-color: #fff;
            box-shadow: 0 0 7px rgba(0, 0, 0, .25);
            border: none;
            border-radius: none;
            padding: 15px;
            cursor: pointer;
            span {
              font-size: 23px;
              color: #333;
              font-weight: 700;
              line-height: 1;
            }
          }
      }
    }
    .catalog-items__item {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3 - 20px);
      height: 100%;
      box-sizing: border-box;
      margin-right: 30px;
      margin-bottom: 30px;
      background: #F8F8F8;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      transition: .4s;
      float: left;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0px 0px 15px rgba(48, 48, 48, 0.68);
        transform: translate(0, -10px);
      }
      .img {
        overflow: hidden;
        height: 215px;
        width: 100%;
        position: relative;
        img {
          height: 100%;
          display: block;
        }
      }
      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        font-weight: 400;
        .name {
          width: 100%;
          max-width: 195px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 15px;
          color: #333;
        }
        .coast {
          font-size: 15px;
          margin: 0 10px;
        }
        .old-coast {
          margin: 0;
          font-size: 12px;
          color: #4F4F4F;
          margin-right: 10px;
        }
        .add-to-basket {
          background: url('../img/header-shopping-icn.svg') no-repeat;
          background-size: contain;
          width: 19px;
          height: 19px;
          display: block;
          transition: .4s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
.catalog-load-items {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

// preorder
.preorder {
  height: 675px;
  padding: 100px 0;
  background: radial-gradient(308.00px at 50% 50%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%), #2D2D2D;
  .order-title {
    font-size: 23px;
    line-height: 31px;
    color: #fff;
  }
  .preorder-slider {
    .preorder-items {
      padding: 60px 15px 0;
      position: relative;
      &:hover {
        .img img {
          transform: translate(0, -60px);
        }
      }
      .img {
        max-height: 265px;
        position: relative;
        display: block;
        z-index: 1;
        img {
          position: absolute;
          z-index: -11;
          top: 0;
          max-width: 100%;
          transition: .4s;
        }
      }
      .game-bundle {
          width: calc(100% - 30px);
          margin: 100px auto 0;
      }
    }
  }
}

// footer
.footer {
  height: 60px;
  background-color: #252525;
  display: flex;
  align-items: center;
  .copyright {
    margin: auto;
    font-size: 14px;
    color: #5E5E5E;
  }
}

// game bundle
.game-bundle {
  position: relative;
  z-index: 2;
  .game-preorder {
    background-color: #333;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-weight: 700;
    width: 50%;
    margin: auto;
    padding: 8px;
  }
  .game-info {
    background-color: #fff;
    text-align: center;
    padding: 40px 25px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 210px;
    .game-name {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
      line-height: 1;
    }
    .game-descr {
      color: #0C0C0C;
      font-size: 15px;
      font-weight: 300;
      line-height: 23px;
      line-height: 1.5;
    }
    .game-coast{
      border-top: 1px solid #E3E3E3;
      margin-top: 30px;
      padding-top: 12px;
      text-align: left;
      font-size: 14px;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .game-link {
        background: url('../img/game-coast-arrow.svg') no-repeat;
        background-size: contain;
        height: 8px;
        width: 22px;
        transition: .3s;
        &:hover {
          transform: scale(1.6);
        }
      }
    }
  }
}
// btn
.rainbow-btn {
  cursor: pointer;
  border: none;
  display: block;
  padding: 3px;
  box-sizing: border-box;
  background: linear-gradient(to right, #FFA800, #FF4141);
  &:hover {
    background: linear-gradient(to left, #FFA800, #FF4141);
  }
  span {
    background: #fff;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 21px;
    color: #000;
    padding: 10px 40px;
  }
}

// page catalog
.error-text {
  text-transform: uppercase;
  text-align: center;
  font-size: 45px;
  line-height: 1.2;
  color: #333;
  padding-bottom: 120px;
}

// checkbox
.checkbox-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  input {
    display: none;
  }
  .checkbox {
    label {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      font-size: 15px;
      cursor: pointer;
      margin-bottom: 14px;
    }
    .checkbox-box{
      width: 15px;
      height: 15px;
      display: flex;
      flex-shrink: 1;
      box-sizing: border-box;
      background: #ededf3;
      text-align: center;
      margin-right: 6px;
      cursor: pointer;
      transition: .3s;
      border: 1px solid #ededf3;
    }
    .checkbox-text {
      display: flex;
      max-width: calc(100% - 25px);
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
    }
    input:checked+label .checkbox-box,
    label:hover .checkbox-box {
      background: linear-gradient(270deg, #FF4141 0%, #FFA800 100%);
      border: 1px solid #DADADA;
    }
  }
}

//coast slider
#range-slider {
  max-width: 100%;
  margin: 0 auto;
  #slider-range {
    background-color: #ECECEC;
    border: none;
    border-radius: 0;
    height: 16px;
  }
  .ui-slider-horizontal .ui-slider-range {
    border-radius: 0;
    background: linear-gradient(270deg, #FF4141 0%, #FFA800 100%);
  }
  .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-active, .ui-widget-content .ui-state-active, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
    background: #fff;
    border: none;
  }
  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    width: 2px;
    height: 20px;
    background: linear-gradient(270deg, #FF4141 0%, #FFA800 100%);
    margin-left: 0;
    top: -2px;
    border: none;
  }
  .range-amount {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 14px;
    .range-amount__item {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #333;
      input {
        margin-left: 7px;
        border: 1px solid #CBCBCB;
        color: #333;
        width: 90px;
        height: 25px;
        padding: 0 8px;
      }
    }
  }
}

// support
.support {
  padding: 100px 0;
  position: relative;
  background: radial-gradient(1270.06px at 50% 50%, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 100%), #2D2D2D;
  .support-title {
    text-transform: uppercase;
    font-size: 70px;
    font-weight: 700;
    margin-bottom: 80px;
    .white {
      color: #fff;
    }
    .grey {
      color: #aaa;
    }
  }
  .form-wrap {
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
    flex-shrink: 1;
    .column {
      width: 470px;
      margin-right: 4px;
    }
    .support-order {
      &__title {
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        padding-bottom: 30px;
      }
      &__descr {
        font-size: 15px;
        color: #fff;
        padding-bottom: 30px;
        max-width: 90%;
      }
      &__input {
        display: flex;
        flex-direction: column;
        ::-webkit-input-placeholder {
          color: #B3B3B3;
        }
        :-ms-input-placeholder {
          color: #B3B3B3;
        }
        ::-ms-input-placeholder {
          color: #B3B3B3;
        }
        ::placeholder {
          color: #B3B3B3;
        }
        input[type="text"],
        textarea {
          background-color: #fff;
          border: none;
          margin-bottom: 14px;
          color: #B3B3B3;
          font-size: 15px;
          font-weight: 300;
          padding: 0 10px;
          resize: none;
          &.open-message {
            height: 221px;
          }
        }
        input[type="text"] {
          height: 40px;
        }
        textarea {
           height: 130px;
           padding-top: 10px;
           margin-bottom: 30px;
        }
        input[type="submit"] {
          width: 225px;
          height: 45px;
          border: none;
          font-size: 16px;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
          background: linear-gradient(179.71deg, #FF4141 -94.83%, #FFA800 328.38%);
        }
      }
    }
  }
  .contacts {
    .contacts-title {
      padding-bottom: 30px;
      font-size: 25px;
      line-height: 1;
      font-weight: 700;
      color: #fff;
    }
    .contacts-item {
      display: flex;
      padding: 0;
      &__link {
        list-style: none;
        background: #fff;
        width: 60px;
        height: 60px;
        display: flex;
        border-radius: 50%;
        margin-right: 30px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        &:last-child {
          margin-right: 0;
        }
        &.vkontakte {
          background-color: #4C6C91;
          background-image: url('../img/contacts-vk.svg');
        }
        &.youtube {
          background-color: #FF0000;
          background-image: url('../img/contacts-youtube.svg');
        }
        &.mail {
          background-color: #0971BD;
          background-image: url('../img/contacts-mail.svg');
        }
        &.facebook {
          background-color: #3B5998;
          background-image: url('../img/contacts-facebook.svg');
        }
        &.instagram {
          background-color: #fff;
          background-image: url('../img/contacts-inst.svg');
        }
      }
    }
  }
}
.succes-wrap {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.85);
  display: flex;
  justify-content: center;
  align-items: center;
  .succes-wrap__text {
    color: #fff;
    font-size: 30px;
    text-transform: uppercase;
  }
}
.carusel-winner {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 22;
  color: #fff;
  font-weight: 400;
  text-transform: none;
  justify-content: center;
  text-align: center;
  margin: auto;
  &__text {
    color: #fff;
    text-transform: uppercase;
  }
  &__win {
    margin: 30px auto;
  }
  &__form {
    display: flex;
    flex-direction: column;
    &--text {
      color: #fff;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 16px;
      padding-bottom: 30px;
    }
    &--input {
      margin-bottom: 10px;
      background-color: #fff;
      border: none;
      padding: 0 10px;
      color: #333;
      font-size: 15px;
      line-height: 1;
      height: 40px;
      font-weight: 300;
    }
    &--btn {
      background: linear-gradient(179.88deg, #FF4141 -94.83%, #FFA800 328.38%);
      border: none;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      text-align: center;
      height: 40px;
      cursor: pointer;
    }
  }
}

// game roulet
.games {
  height: 615px;
  background: radial-gradient(308.00px at 50% 50%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%), #2D2D2D;
  .carusel-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    .carusel-round {
      display: flex;
      flex-shrink: 1;
      padding: 0;
      margin: 0;
      margin-top: 90px;
      margin-bottom: 40px;
      width: 100%;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: -90px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-left: 80px solid transparent;
        border-right: 80px solid transparent;
        border-top: 130px solid #fff;
      }
      &__item {
        display: flex;
        flex-shrink: 0;
        width: calc(100% / 3 - 25px);
        list-style: none;
        height: 350px;
        margin-right: 50px;
        img {
          width: 100%;
        }
      }
    }
    .carusel-start {
      cursor: pointer;
      height: 45px;
      width: 230px;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      background: linear-gradient(179.71deg, #FF4141 -94.83%, #FFA800 328.38%);
    }
  }
}
.games-item {
  padding: 100px 0;
  &__title {
    padding-bottom: 30px;
    font-size: 21px;
    color: #333;
    font-weight: 500;
  }
  &__catalog {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1;
    &--item {
      width: calc(100% / 3 - 20px);
      margin-right: 30px;
      margin-bottom: 30px;
      display: flex;
      overflow: hidden;
      font-size: 0;
      transition: .3s;
      border: 2px solid transparent;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-0px) scale(1.1);
        border-color: orange;
        box-shadow: 0 0 17px rgba(0,0,0,.5)
      }
    }
    .catalog-load-items {
      margin-top: 30px;
    }
  }
}

// order
.order {
  padding: 100px 0;
  &-basket {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1;
    &__item {
      width: calc(25% - 30px);
      margin-right: 30px;
      margin-bottom: 30px;
      display: flex;
      position: relative;
      justify-content: space-between;
      flex-direction: column;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0px 0px 15px rgba(48, 48, 48, 0.68);
      }
      .img {
        height: 165px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          transition: .4s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .details {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        .name {
          font-size: 16px;
          color: #333;
          padding-top: 10px;
          max-width: 205px;
          max-height: 45px;
          overflow: hidden;
        }
        .category {
          margin-top: 10px;
          font-size: 14px;
          color: #BDBDBD;
        }
        .remove {
          background: url('../img/remove.svg') no-repeat;
          background-size: contain;
          width: 12px;
          height: 12px;
          display: block;
          margin-top: 15px;
          transition: .3s;
          &:hover {
            transform: rotate(90deg) scale(1.4);
          }
        }
      }
      .coast {
        padding: 25px 15px 15px;
        font-size: 16px;
        font-weight: 300;
        color: #333;
      }
    }
  }
  &-basket__total {
    margin-bottom: 100px;
    font-size: 18px;
    color: #333;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  }
  &-payment {
    &__method {
      display: flex;
      flex-shrink: 1;
      width: 100%;
    }
    &__method > .forms {
      width: calc(100% - 170px * 4 - 120px);
      .confirm-payment {
        display: flex;
        flex-direction: column;
        input[type="text"] {
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
          background-color: #fff;
          border: none;
          height: 40px;
          padding: 0 10px;
          font-size: 15px;
          font-weight: 300;
          margin-bottom: 10px;
        }
        input[type="submit"] {
          height: 40px;
          background: linear-gradient(179.82deg, #FF4141 -94.83%, #FFA800 328.38%);
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
          color: #fff;
          font-size: 15px;
          font-weight: 500;
          margin-top: 5px;
          cursor: pointer;
        }
        .checkbox-wrap {
          margin-top: 30px;
          .checkbox > label {
            align-items: flex-start;
            justify-content: space-between;
            flex-shrink: 1;
          }
        }
      }
    }
    &__method > .column {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      width: calc(170px * 4 + 120px);
      .order-payment__item {
        width: 170px;
        height: 170px;
        margin-right: 30px;
        margin-bottom: 30px;
        background-color: #fff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        &.webmoney {
          background-image: url('../img/pay-webmoney.svg');
        }
        &.yandex {
          background-image: url('../img/pay-yandex.svg');
        }
        &.qiwi {
          background-image: url('../img/pay-qiwi.svg');
        }
        &.card {
          background-image: url('../img/pay-bank.svg');
        }
        &.beeline {
          background-image: url('../img/pay-beeline.svg');
        }
        &.mts {
          background-image: url('../img/pay-mts.svg');
        }
        &.megafon {
          background-image: url('../img/pay-megafon.svg');
        }
        &.other {
          background-image: url('../img/pay-other.svg');
        }
      }
    }
  }
}
.section-title__small,
.section-title__medium {
  padding-bottom: 30px;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
}
.section-title__medium {
  font-size: 22px;
}

// full page
.games-page {
  padding: 100px 0;
  .flex {
    align-items: flex-start;
  }
  .picture-slide {
    display: flex;
    margin-right: 30px;
    .picture-slide__big {
      width: 470px;
      height: 330px;
      margin-right: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .scroll {
      height: 100%;
      overflow: hidden;
    }
    .picture-slide__preview {
      height: 330px;
      overflow: auto;
      .picture-slide__preview--item {
        width: 170px;
        height: 98px;
        margin-bottom: 18px;
        border: 2px solid #fff;
        transition: .4s;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        &.current, &:hover {
          border-color: orange;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .game-description {
    .game-info {
      margin-bottom: 50px;
      li {
        color: #000;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 19px;
        display: flex;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          font-weight: 400;
          min-width: 100px;
          margin-right: 30px;
        }
      }
    }
    .game-order {
      display: flex;
      .coast-info {
        display: flex;
        align-items: center;
        width: 130px;
        .coast {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
  }
  .text-info {
    margin-top: 100px;
  }
}
.orange-btn {
  height: 45px;
  min-width: 225px;
  background: linear-gradient(179.71deg, #FF4141 -94.83%, #FFA800 328.38%);
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.catalog-tabs {
  margin-bottom: 30px;
  font-size: 0;
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: #E0E0E0;
    height: 1px;
    width: 100%;
  }
  & li {
    display: inline-block;
    list-style-type: none;
    position: relative;
    padding: 0 15px 7px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: .4s;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      background-image: none;
      background: #E0E0E0;
      height: 2px;
      width: 0%;
      transition: .4s;
    }
    &.active-tab {
      &:after {
        width: 100%;
        background-image: linear-gradient(110deg, #FF4141 0%, #FFA800 100%);
      }
    }
  }
}
.container-tab {
  max-width: 100%;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  color: #333;
  display: none;
  &.active {
    display: block;
  }
  .short-descr {
    max-width: 60%;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 50px;
  }
  .full-descr {
    .descr-title {
      display: block;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 16px;
    }
    .descr-info {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      & li {
        font-weight: 300;
        color: #000;
        display: flex;
        margin-bottom: 19px;
        & span {
          font-weight: 500;
          width: 200px;
        }
      }
    }
    p {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 25px;
    }
  }
}
.coast {
  font-size: 15px;
  margin-right: 15px;
}
.old-coast {
  font-size: 12px;
  color: #4F4F4F;
}
.imageback{
  background-size: cover !important;
  transition: .4s;
  width: 100%;
  height: 100%;
  &:hover{
    transform: scale(1.1);
  }
}