@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../img/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: url("../img/preorder-arrow.svg");
  background-size: contain;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    opacity: .8;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  [dir="rtl"] .slick-prev:before {
    content: "→"; }

.slick-next {
  right: -25px;
  transform: rotate(180deg); }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  [dir="rtl"] .slick-next:before {
    content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: #fff;
      border-radius: 50%;
      display: block;
      height: 15px;
      width: 15px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 15px;
        height: 15px;
        font-family: "slick";
        font-size: 6px;
        line-height: 15px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      background: linear-gradient(206.57deg, #1A4CCC 8.33%, #00A3FF 83.33%);
      opacity: 1; }

* {
  font-family: 'Roboto', sans-serif;
  outline: none;
  color: #333;
  box-sizing: border-box;
  text-decoration: none; }

::-webkit-input-placeholder {
  color: #333; }

:-ms-input-placeholder {
  color: #333; }

::-ms-input-placeholder {
  color: #333; }

::placeholder {
  color: #333; }

body {
  background: #FBFBFB;
  font-family: 'Roboto', sans-serif;
  font-size: 12px; }

img {
  display: block;
  max-width: 100%; }

ul, li {
  padding: 0;
  margin: 0;
  list-style: none; }

.wrap {
  max-width: 1170px;
  padding: 0 10px;
  margin: 0 auto; }

.flex {
  display: flex;
  align-items: center; }

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background-color: #fff; }
  #preloader #cube-loader {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed; }
    #preloader #cube-loader .caption {
      margin: 0 auto; }
    #preloader #cube-loader .cube-loader {
      width: 73px;
      height: 73px;
      margin: 0 auto;
      margin-top: 49px;
      position: relative;
      transform: rotateZ(45deg); }
      #preloader #cube-loader .cube-loader .cube {
        position: relative;
        transform: rotateZ(45deg);
        width: 50%;
        height: 50%;
        float: left;
        transform: scale(1.1); }
      #preloader #cube-loader .cube-loader .cube:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F56E17;
        animation: cube-loader 2.76s infinite linear both;
        transform-origin: 100% 100%; }
      #preloader #cube-loader .cube-loader .loader-2 {
        transform: scale(1.1) rotateZ(90deg); }
      #preloader #cube-loader .cube-loader .loader-3 {
        transform: scale(1.1) rotateZ(180deg); }
      #preloader #cube-loader .cube-loader .loader-4 {
        transform: scale(1.1) rotateZ(270deg); }
      #preloader #cube-loader .cube-loader .loader-2:before {
        animation-delay: 0.35s; }
      #preloader #cube-loader .cube-loader .loader-3:before {
        animation-delay: 0.69s; }
      #preloader #cube-loader .cube-loader .loader-4:before {
        animation-delay: 1.04s; }

@keyframes cube-loader {
  0%, 10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0; } }

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear; }

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear; }

.header {
  height: 63px;
  background: #fff;
  position: relative;
  z-index: 20;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5); }
  .header .flex {
    height: 100%;
    justify-content: space-between; }
  .header .header-left {
    display: flex; }
    .header .header-left .header-logo {
      background: url("../img/header-logo.svg") no-repeat;
      width: 79px;
      display: block;
      height: 19px;
      transition: .3s; }
      .header .header-left .header-logo:hover {
        transform: scale(1.2); }
    .header .header-left .header-nav {
      display: flex;
      margin-left: 70px; }
      .header .header-left .header-nav .header-nav__link {
        margin: 0 20px;
        text-transform: uppercase;
        position: relative;
        transition: all .4s; }
        .header .header-left .header-nav .header-nav__link:before {
          content: '';
          height: 3px;
          width: 0%;
          background: transparent;
          background-position: center 0;
          position: absolute;
          top: -22px;
          transition: .2s; }
        .header .header-left .header-nav .header-nav__link:hover:before, .header .header-left .header-nav .header-nav__link--active:before {
          width: 100%;
          background: linear-gradient(270deg, #FF4141 0%, #FFA800 100%); }
        .header .header-left .header-nav .header-nav__link:last-child {
          margin-right: 0; }
  .header .header-right {
    display: flex;
    align-items: center; }
    .header .header-right .header-search {
      width: 27px;
      height: 27px;
      overflow: hidden;
      transition: .4s; }
      .header .header-right .header-search:hover {
        width: 224px; }
        .header .header-right .header-search:hover .header-search-form {
          width: 100%; }
      .header .header-right .header-search .header-search-form {
        width: 27px;
        height: 27px;
        background: url("../img/header-search-icn.svg") no-repeat; }
      .header .header-right .header-search .header-search-input {
        border: none;
        background-color: transparent;
        margin-left: 27px;
        padding: 0 5px;
        width: 197px;
        height: 27px;
        border-bottom: 1px solid #000;
        box-sizing: border-box; }
    .header .header-right .header-basket {
      width: 27px;
      height: 27px;
      margin-left: 40px;
      position: relative;
      background: url("../img/header-shopping-icn.svg") no-repeat 0 50%; }
      .header .header-right .header-basket .header-basket__item {
        position: absolute;
        right: -5px;
        top: -5px;
        color: #fff;
        font-size: 9px;
        text-transform: uppercase;
        background: linear-gradient(206.63deg, #1A4CCC 8.33%, #00A3FF 83.33%);
        border-radius: 50%;
        padding: 3px;
        display: flex;
        align-items: center;
        transition: .3s; }
      .header .header-right .header-basket:hover .header-basket__item {
        transform: scale(1.2); }

.news {
  padding: 40px 0 45px;
  display: flex;
  background: radial-gradient(409.5px at 50% 50%, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 100%), #2D2D2D; }
  .news .news-slider > div .news-slider__item {
    padding: 0 20px; }
  .news .news-slider .news-slider__item {
    position: relative;
    overflow: hidden; }
    .news .news-slider .news-slider__item .img {
      height: 490px;
      width: 100%;
      overflow: hidden; }
      .news .news-slider .news-slider__item .img img {
        display: block;
        width: auto;
        width: 100%;
        height: 100%;
        transition: .4s; }
        .news .news-slider .news-slider__item .img img:hover {
          transform: scale(1.1); }
  .news .news-slider .game-bundle {
    position: absolute;
    right: 10px;
    bottom: 32px;
    background: #fff;
    max-width: 80%;
    min-width: 65%;
    height: 200px;
    display: flex; }
    .news .news-slider .game-bundle .game-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 40px 25px 20px; }
      .news .news-slider .game-bundle .game-info .game-name {
        margin-bottom: 10px;
        line-height: 1; }
      .news .news-slider .game-bundle .game-info .game-descr {
        margin: 0;
        line-height: 1.2; }

.platforms {
  height: 165px;
  padding: 16px 0;
  background: #3e3e3e;
  box-sizing: border-box; }
  .platforms .platform-items {
    height: 133px;
    display: flex;
    justify-content: space-between; }
    .platforms .platform-items .platform-items__link {
      width: 100%;
      display: flex; }
      .platforms .platform-items .platform-items__link .platform-items__img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto;
        transition: .3s; }
        .platforms .platform-items .platform-items__link .platform-items__img.steam {
          background-color: #A6A9B1;
          background-image: url("../img/platform-steam.svg"); }
        .platforms .platform-items .platform-items__link .platform-items__img.origin {
          background-color: #F56E17;
          background-image: url("../img/platform-origin.svg"); }
        .platforms .platform-items .platform-items__link .platform-items__img.ubisoft {
          background-color: #0071FE;
          background-image: url("../img/platform-ubisoft.svg"); }
        .platforms .platform-items .platform-items__link .platform-items__img.rockstar {
          background-color: #FFD379;
          background-image: url("../img/platform-rockstar.svg"); }
        .platforms .platform-items .platform-items__link .platform-items__img.minecraft {
          background-color: #C5ECA6;
          background-image: url("../img/platform-minecraft.svg"); }
        .platforms .platform-items .platform-items__link .platform-items__img:hover {
          background-position: 50% 40%; }

.catalog {
  padding: 100px 0; }
  .catalog .catalog-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-shrink: 1;
    box-sizing: border-box;
    width: 100%;
    position: relative; }
    .catalog .catalog-items.grid {
      display: flex;
      flex-shrink: 1;
      justify-content: space-between; }
      .catalog .catalog-items.grid .catalog-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 100%;
        width: calc(100% / 1.5 + 20px);
        flex-shrink: 1; }
        .catalog .catalog-items.grid .catalog-grid .catalog-items__item {
          width: calc(50% - 30px); }
          .catalog .catalog-items.grid .catalog-grid .catalog-items__item:nth-child(3n) {
            margin-right: auto; }
      .catalog .catalog-items.grid .catalog-filters {
        height: 100%;
        width: calc(100% / 3 - 20px);
        display: flex;
        flex-direction: column;
        margin-top: -68px; }
        .catalog .catalog-items.grid .catalog-filters .filter-item {
          padding: 30px 22px 15px;
          margin-bottom: 10px;
          background: #fff;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25); }
          .catalog .catalog-items.grid .catalog-filters .filter-item .filter-item__title {
            text-align: center;
            font-size: 23px;
            line-height: 1;
            font-weight: 700;
            padding-bottom: 20px; }
        .catalog .catalog-items.grid .catalog-filters .filter-btn {
          background-color: #fff;
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
          border: none;
          border-radius: none;
          padding: 15px;
          cursor: pointer; }
          .catalog .catalog-items.grid .catalog-filters .filter-btn span {
            font-size: 23px;
            color: #333;
            font-weight: 700;
            line-height: 1; }
    .catalog .catalog-items .catalog-items__item {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3 - 20px);
      height: 100%;
      box-sizing: border-box;
      margin-right: 30px;
      margin-bottom: 30px;
      background: #F8F8F8;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      transition: .4s;
      float: left; }
      .catalog .catalog-items .catalog-items__item:nth-child(3n) {
        margin-right: 0; }
      .catalog .catalog-items .catalog-items__item:hover {
        box-shadow: 0px 0px 15px rgba(48, 48, 48, 0.68);
        transform: translate(0, -10px); }
      .catalog .catalog-items .catalog-items__item .img {
        overflow: hidden;
        height: 215px;
        width: 100%;
        position: relative; }
        .catalog .catalog-items .catalog-items__item .img img {
          height: 100%;
          display: block; }
      .catalog .catalog-items .catalog-items__item .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        font-weight: 400; }
        .catalog .catalog-items .catalog-items__item .details .name {
          width: 100%;
          max-width: 195px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 15px;
          color: #333; }
        .catalog .catalog-items .catalog-items__item .details .coast {
          font-size: 15px;
          margin: 0 10px; }
        .catalog .catalog-items .catalog-items__item .details .old-coast {
          margin: 0;
          font-size: 12px;
          color: #4F4F4F;
          margin-right: 10px; }
        .catalog .catalog-items .catalog-items__item .details .add-to-basket {
          background: url("../img/header-shopping-icn.svg") no-repeat;
          background-size: contain;
          width: 19px;
          height: 19px;
          display: block;
          transition: .4s; }
          .catalog .catalog-items .catalog-items__item .details .add-to-basket:hover {
            transform: scale(1.2); }

.catalog-load-items {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.preorder {
  height: 675px;
  padding: 100px 0;
  background: radial-gradient(308px at 50% 50%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%), #2D2D2D; }
  .preorder .order-title {
    font-size: 23px;
    line-height: 31px;
    color: #fff; }
  .preorder .preorder-slider .preorder-items {
    padding: 60px 15px 0;
    position: relative; }
    .preorder .preorder-slider .preorder-items:hover .img img {
      transform: translate(0, -60px); }
    .preorder .preorder-slider .preorder-items .img {
      max-height: 265px;
      position: relative;
      display: block;
      z-index: 1; }
      .preorder .preorder-slider .preorder-items .img img {
        position: absolute;
        z-index: -11;
        top: 0;
        max-width: 100%;
        transition: .4s; }
    .preorder .preorder-slider .preorder-items .game-bundle {
      width: calc(100% - 30px);
      margin: 100px auto 0; }

.footer {
  height: 60px;
  background-color: #252525;
  display: flex;
  align-items: center; }
  .footer .copyright {
    margin: auto;
    font-size: 14px;
    color: #5E5E5E; }

.game-bundle {
  position: relative;
  z-index: 2; }
  .game-bundle .game-preorder {
    background-color: #333;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-weight: 700;
    width: 50%;
    margin: auto;
    padding: 8px; }
  .game-bundle .game-info {
    background-color: #fff;
    text-align: center;
    padding: 40px 25px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 210px; }
    .game-bundle .game-info .game-name {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
      line-height: 1; }
    .game-bundle .game-info .game-descr {
      color: #0C0C0C;
      font-size: 15px;
      font-weight: 300;
      line-height: 23px;
      line-height: 1.5; }
    .game-bundle .game-info .game-coast {
      border-top: 1px solid #E3E3E3;
      margin-top: 30px;
      padding-top: 12px;
      text-align: left;
      font-size: 14px;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .game-bundle .game-info .game-coast .game-link {
        background: url("../img/game-coast-arrow.svg") no-repeat;
        background-size: contain;
        height: 8px;
        width: 22px;
        transition: .3s; }
        .game-bundle .game-info .game-coast .game-link:hover {
          transform: scale(1.6); }

.rainbow-btn {
  cursor: pointer;
  border: none;
  display: block;
  padding: 3px;
  box-sizing: border-box;
  background: linear-gradient(to right, #FFA800, #FF4141); }
  .rainbow-btn:hover {
    background: linear-gradient(to left, #FFA800, #FF4141); }
  .rainbow-btn span {
    background: #fff;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 21px;
    color: #000;
    padding: 10px 40px; }

.error-text {
  text-transform: uppercase;
  text-align: center;
  font-size: 45px;
  line-height: 1.2;
  color: #333;
  padding-bottom: 120px; }

.checkbox-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .checkbox-wrap input {
    display: none; }
  .checkbox-wrap .checkbox label {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 14px; }
  .checkbox-wrap .checkbox .checkbox-box {
    width: 15px;
    height: 15px;
    display: flex;
    flex-shrink: 1;
    box-sizing: border-box;
    background: #ededf3;
    text-align: center;
    margin-right: 6px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #ededf3; }
  .checkbox-wrap .checkbox .checkbox-text {
    display: flex;
    max-width: calc(100% - 25px);
    font-weight: 300;
    font-size: 12px;
    line-height: 15px; }
  .checkbox-wrap .checkbox input:checked + label .checkbox-box,
  .checkbox-wrap .checkbox label:hover .checkbox-box {
    background: linear-gradient(270deg, #FF4141 0%, #FFA800 100%);
    border: 1px solid #DADADA; }

#range-slider {
  max-width: 100%;
  margin: 0 auto; }
  #range-slider #slider-range {
    background-color: #ECECEC;
    border: none;
    border-radius: 0;
    height: 16px; }
  #range-slider .ui-slider-horizontal .ui-slider-range {
    border-radius: 0;
    background: linear-gradient(270deg, #FF4141 0%, #FFA800 100%); }
  #range-slider .ui-state-hover, #range-slider .ui-widget-content .ui-state-hover, #range-slider .ui-widget-header .ui-state-hover, #range-slider .ui-state-active, #range-slider .ui-widget-content .ui-state-active, #range-slider .ui-state-focus, #range-slider .ui-widget-content .ui-state-focus, #range-slider .ui-widget-header .ui-state-focus, #range-slider .ui-button:hover, #range-slider .ui-button:focus {
    background: #fff;
    border: none; }
  #range-slider .ui-state-default, #range-slider .ui-widget-content .ui-state-default, #range-slider .ui-widget-header .ui-state-default, #range-slider .ui-button, #range-slider html .ui-button.ui-state-disabled:hover, #range-slider html .ui-button.ui-state-disabled:active {
    width: 2px;
    height: 20px;
    background: linear-gradient(270deg, #FF4141 0%, #FFA800 100%);
    margin-left: 0;
    top: -2px;
    border: none; }
  #range-slider .range-amount {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 14px; }
    #range-slider .range-amount .range-amount__item {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #333; }
      #range-slider .range-amount .range-amount__item input {
        margin-left: 7px;
        border: 1px solid #CBCBCB;
        color: #333;
        width: 90px;
        height: 25px;
        padding: 0 8px; }

.support {
  padding: 100px 0;
  position: relative;
  background: radial-gradient(1270.06px at 50% 50%, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 100%), #2D2D2D; }
  .support .support-title {
    text-transform: uppercase;
    font-size: 70px;
    font-weight: 700;
    margin-bottom: 80px; }
    .support .support-title .white {
      color: #fff; }
    .support .support-title .grey {
      color: #aaa; }
  .support .form-wrap {
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
    flex-shrink: 1; }
    .support .form-wrap .column {
      width: 470px;
      margin-right: 4px; }
    .support .form-wrap .support-order__title {
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      padding-bottom: 30px; }
    .support .form-wrap .support-order__descr {
      font-size: 15px;
      color: #fff;
      padding-bottom: 30px;
      max-width: 90%; }
    .support .form-wrap .support-order__input {
      display: flex;
      flex-direction: column; }
      .support .form-wrap .support-order__input ::-webkit-input-placeholder {
        color: #B3B3B3; }
      .support .form-wrap .support-order__input :-ms-input-placeholder {
        color: #B3B3B3; }
      .support .form-wrap .support-order__input ::-ms-input-placeholder {
        color: #B3B3B3; }
      .support .form-wrap .support-order__input ::placeholder {
        color: #B3B3B3; }
      .support .form-wrap .support-order__input input[type="text"],
      .support .form-wrap .support-order__input textarea {
        background-color: #fff;
        border: none;
        margin-bottom: 14px;
        color: #B3B3B3;
        font-size: 15px;
        font-weight: 300;
        padding: 0 10px;
        resize: none; }
        .support .form-wrap .support-order__input input[type="text"].open-message,
        .support .form-wrap .support-order__input textarea.open-message {
          height: 221px; }
      .support .form-wrap .support-order__input input[type="text"] {
        height: 40px; }
      .support .form-wrap .support-order__input textarea {
        height: 130px;
        padding-top: 10px;
        margin-bottom: 30px; }
      .support .form-wrap .support-order__input input[type="submit"] {
        width: 225px;
        height: 45px;
        border: none;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        background: linear-gradient(179.71deg, #FF4141 -94.83%, #FFA800 328.38%); }
  .support .contacts .contacts-title {
    padding-bottom: 30px;
    font-size: 25px;
    line-height: 1;
    font-weight: 700;
    color: #fff; }
  .support .contacts .contacts-item {
    display: flex;
    padding: 0; }
    .support .contacts .contacts-item__link {
      list-style: none;
      background: #fff;
      width: 60px;
      height: 60px;
      display: flex;
      border-radius: 50%;
      margin-right: 30px;
      background-repeat: no-repeat;
      background-position: 50% 50%; }
      .support .contacts .contacts-item__link:last-child {
        margin-right: 0; }
      .support .contacts .contacts-item__link.vkontakte {
        background-color: #4C6C91;
        background-image: url("../img/contacts-vk.svg"); }
      .support .contacts .contacts-item__link.youtube {
        background-color: #FF0000;
        background-image: url("../img/contacts-youtube.svg"); }
      .support .contacts .contacts-item__link.mail {
        background-color: #0971BD;
        background-image: url("../img/contacts-mail.svg"); }
      .support .contacts .contacts-item__link.facebook {
        background-color: #3B5998;
        background-image: url("../img/contacts-facebook.svg"); }
      .support .contacts .contacts-item__link.instagram {
        background-color: #fff;
        background-image: url("../img/contacts-inst.svg"); }

.succes-wrap {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center; }
  .succes-wrap .succes-wrap__text {
    color: #fff;
    font-size: 30px;
    text-transform: uppercase; }

.carusel-winner {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 22;
  color: #fff;
  font-weight: 400;
  text-transform: none;
  justify-content: center;
  text-align: center;
  margin: auto; }
  .carusel-winner__text {
    color: #fff;
    text-transform: uppercase; }
  .carusel-winner__win {
    margin: 30px auto; }
  .carusel-winner__form {
    display: flex;
    flex-direction: column; }
    .carusel-winner__form--text {
      color: #fff;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 16px;
      padding-bottom: 30px; }
    .carusel-winner__form--input {
      margin-bottom: 10px;
      background-color: #fff;
      border: none;
      padding: 0 10px;
      color: #333;
      font-size: 15px;
      line-height: 1;
      height: 40px;
      font-weight: 300; }
    .carusel-winner__form--btn {
      background: linear-gradient(179.88deg, #FF4141 -94.83%, #FFA800 328.38%);
      border: none;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      text-align: center;
      height: 40px;
      cursor: pointer; }

.games {
  height: 615px;
  background: radial-gradient(308px at 50% 50%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%), #2D2D2D; }
  .games .carusel-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%; }
    .games .carusel-wrap .carusel-round {
      display: flex;
      flex-shrink: 1;
      padding: 0;
      margin: 0;
      margin-top: 90px;
      margin-bottom: 40px;
      width: 100%;
      position: relative; }
      .games .carusel-wrap .carusel-round:before {
        content: '';
        position: absolute;
        top: -90px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-left: 80px solid transparent;
        border-right: 80px solid transparent;
        border-top: 130px solid #fff; }
      .games .carusel-wrap .carusel-round__item {
        display: flex;
        flex-shrink: 0;
        width: calc(100% / 3 - 25px);
        list-style: none;
        height: 350px;
        margin-right: 50px; }
        .games .carusel-wrap .carusel-round__item img {
          width: 100%; }
    .games .carusel-wrap .carusel-start {
      cursor: pointer;
      height: 45px;
      width: 230px;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      background: linear-gradient(179.71deg, #FF4141 -94.83%, #FFA800 328.38%); }

.games-item {
  padding: 100px 0; }
  .games-item__title {
    padding-bottom: 30px;
    font-size: 21px;
    color: #333;
    font-weight: 500; }
  .games-item__catalog {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1; }
    .games-item__catalog--item {
      width: calc(100% / 3 - 20px);
      margin-right: 30px;
      margin-bottom: 30px;
      display: flex;
      overflow: hidden;
      font-size: 0;
      transition: .3s;
      border: 2px solid transparent; }
      .games-item__catalog--item:nth-child(3n) {
        margin-right: 0; }
      .games-item__catalog--item:hover {
        transform: translateY(0px) scale(1.1);
        border-color: orange;
        box-shadow: 0 0 17px rgba(0, 0, 0, 0.5); }
    .games-item__catalog .catalog-load-items {
      margin-top: 30px; }

.order {
  padding: 100px 0; }
  .order-basket {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1; }
    .order-basket__item {
      width: calc(25% - 30px);
      margin-right: 30px;
      margin-bottom: 30px;
      display: flex;
      position: relative;
      justify-content: space-between;
      flex-direction: column;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25); }
      .order-basket__item:nth-child(4n) {
        margin-right: 0; }
      .order-basket__item:hover {
        box-shadow: 0px 0px 15px rgba(48, 48, 48, 0.68); }
      .order-basket__item .img {
        height: 165px;
        overflow: hidden; }
        .order-basket__item .img img {
          height: 100%;
          width: 100%;
          transition: .4s; }
          .order-basket__item .img img:hover {
            transform: scale(1.2); }
      .order-basket__item .details {
        padding: 0 15px;
        display: flex;
        justify-content: space-between; }
        .order-basket__item .details .name {
          font-size: 16px;
          color: #333;
          padding-top: 10px;
          max-width: 205px;
          max-height: 45px;
          overflow: hidden; }
        .order-basket__item .details .category {
          margin-top: 10px;
          font-size: 14px;
          color: #BDBDBD; }
        .order-basket__item .details .remove {
          background: url("../img/remove.svg") no-repeat;
          background-size: contain;
          width: 12px;
          height: 12px;
          display: block;
          margin-top: 15px;
          transition: .3s; }
          .order-basket__item .details .remove:hover {
            transform: rotate(90deg) scale(1.4); }
      .order-basket__item .coast {
        padding: 25px 15px 15px;
        font-size: 16px;
        font-weight: 300;
        color: #333; }
  .order-basket__total {
    margin-bottom: 100px;
    font-size: 18px;
    color: #333;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25); }
  .order-payment__method {
    display: flex;
    flex-shrink: 1;
    width: 100%; }
  .order-payment__method > .forms {
    width: calc(100% - 170px * 4 - 120px); }
    .order-payment__method > .forms .confirm-payment {
      display: flex;
      flex-direction: column; }
      .order-payment__method > .forms .confirm-payment input[type="text"] {
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
        background-color: #fff;
        border: none;
        height: 40px;
        padding: 0 10px;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 10px; }
      .order-payment__method > .forms .confirm-payment input[type="submit"] {
        height: 40px;
        background: linear-gradient(179.82deg, #FF4141 -94.83%, #FFA800 328.38%);
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        margin-top: 5px;
        cursor: pointer; }
      .order-payment__method > .forms .confirm-payment .checkbox-wrap {
        margin-top: 30px; }
        .order-payment__method > .forms .confirm-payment .checkbox-wrap .checkbox > label {
          align-items: flex-start;
          justify-content: space-between;
          flex-shrink: 1; }
  .order-payment__method > .column {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1;
    width: calc(170px * 4 + 120px); }
    .order-payment__method > .column .order-payment__item {
      width: 170px;
      height: 170px;
      margin-right: 30px;
      margin-bottom: 30px;
      background-color: #fff;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      background-repeat: no-repeat;
      background-position: 50% 50%; }
      .order-payment__method > .column .order-payment__item.webmoney {
        background-image: url("../img/pay-webmoney.svg"); }
      .order-payment__method > .column .order-payment__item.yandex {
        background-image: url("../img/pay-yandex.svg"); }
      .order-payment__method > .column .order-payment__item.qiwi {
        background-image: url("../img/pay-qiwi.svg"); }
      .order-payment__method > .column .order-payment__item.card {
        background-image: url("../img/pay-bank.svg"); }
      .order-payment__method > .column .order-payment__item.beeline {
        background-image: url("../img/pay-beeline.svg"); }
      .order-payment__method > .column .order-payment__item.mts {
        background-image: url("../img/pay-mts.svg"); }
      .order-payment__method > .column .order-payment__item.megafon {
        background-image: url("../img/pay-megafon.svg"); }
      .order-payment__method > .column .order-payment__item.other {
        background-image: url("../img/pay-other.svg"); }

.section-title__small,
.section-title__medium {
  padding-bottom: 30px;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  line-height: 1; }

.section-title__medium {
  font-size: 22px; }

.games-page {
  padding: 100px 0; }
  .games-page .flex {
    align-items: flex-start; }
  .games-page .picture-slide {
    display: flex;
    margin-right: 30px; }
    .games-page .picture-slide .picture-slide__big {
      width: 470px;
      height: 330px;
      margin-right: 30px; }
      .games-page .picture-slide .picture-slide__big img {
        width: 100%;
        height: 100%; }
    .games-page .picture-slide .scroll {
      height: 100%;
      overflow: hidden; }
    .games-page .picture-slide .picture-slide__preview {
      height: 330px;
      overflow: auto; }
      .games-page .picture-slide .picture-slide__preview .picture-slide__preview--item {
        width: 170px;
        height: 98px;
        margin-bottom: 18px;
        border: 2px solid #fff;
        transition: .4s;
        cursor: pointer; }
        .games-page .picture-slide .picture-slide__preview .picture-slide__preview--item img {
          width: 100%;
          height: 100%; }
        .games-page .picture-slide .picture-slide__preview .picture-slide__preview--item.current, .games-page .picture-slide .picture-slide__preview .picture-slide__preview--item:hover {
          border-color: orange; }
        .games-page .picture-slide .picture-slide__preview .picture-slide__preview--item:last-child {
          margin-bottom: 0; }
  .games-page .game-description .game-info {
    margin-bottom: 50px; }
    .games-page .game-description .game-info li {
      color: #000;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 19px;
      display: flex; }
      .games-page .game-description .game-info li:last-child {
        margin-bottom: 0; }
      .games-page .game-description .game-info li span {
        font-weight: 400;
        min-width: 100px;
        margin-right: 30px; }
  .games-page .game-description .game-order {
    display: flex; }
    .games-page .game-description .game-order .coast-info {
      display: flex;
      align-items: center;
      width: 130px; }
      .games-page .game-description .game-order .coast-info .coast {
        font-size: 20px;
        margin-right: 5px; }
  .games-page .text-info {
    margin-top: 100px; }

.orange-btn {
  height: 45px;
  min-width: 225px;
  background: linear-gradient(179.71deg, #FF4141 -94.83%, #FFA800 328.38%);
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center; }

.catalog-tabs {
  margin-bottom: 30px;
  font-size: 0;
  display: inline-block;
  position: relative; }
  .catalog-tabs:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: #E0E0E0;
    height: 1px;
    width: 100%; }
  .catalog-tabs li {
    display: inline-block;
    list-style-type: none;
    position: relative;
    padding: 0 15px 7px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: .4s; }
    .catalog-tabs li:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      background-image: none;
      background: #E0E0E0;
      height: 2px;
      width: 0%;
      transition: .4s; }
    .catalog-tabs li.active-tab:after {
      width: 100%;
      background-image: linear-gradient(110deg, #FF4141 0%, #FFA800 100%); }

.container-tab {
  max-width: 100%;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  color: #333;
  display: none; }
  .container-tab.active {
    display: block; }
  .container-tab .short-descr {
    max-width: 60%;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 50px; }
  .container-tab .full-descr .descr-title {
    display: block;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px; }
  .container-tab .full-descr .descr-info {
    margin-bottom: 40px; }
    .container-tab .full-descr .descr-info:last-child {
      margin-bottom: 0; }
    .container-tab .full-descr .descr-info li {
      font-weight: 300;
      color: #000;
      display: flex;
      margin-bottom: 19px; }
      .container-tab .full-descr .descr-info li span {
        font-weight: 500;
        width: 200px; }
  .container-tab .full-descr p {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 25px; }

.coast {
  font-size: 15px;
  margin-right: 15px; }

.old-coast {
  font-size: 12px;
  color: #4F4F4F; }

.imageback {
  background-size: cover !important;
  transition: .4s;
  width: 100%;
  height: 100%; }
  .imageback:hover {
    transform: scale(1.1); }
